import React, { Component } from "react"
import rightArrow from "../assets/img/right-arrow.png"
import checkModal from "../assets/img/check-modal.png"
import checkup2 from "../assets/img/checkup2.png"

class Checkup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      display: false,
      value: "",
    }
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(e) {
    e.preventDefault()
    this.setState({ value: e.target.value })
  }
  showModal() {
    this.setState({ display: !this.state.display })
    document.querySelector(".check-modal").classList.add("active-modal")
  }
  closeModal() {
    this.setState({ display: !this.state.display })
    document.querySelector(".check-modal").classList.remove("active-modal")
  }
  handleSubmit(e) {
    e.preventDefault()
    const title = document.title.split("|", 1).toString()
    const response = fetch(
      "https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7",
      {
        method: "POST",
        body: JSON.stringify({
          text_327649: title,
          text_204202: document.querySelector("#site").value,
          text_775908: document.querySelector("#name").value,
          text_532192: document.querySelector("#email").value,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
    response.then(res => {
      if (res.status == 200) {
        document.querySelector(".status").innerHTML =
          "Mesajınız başarıyla gönderilmiştir."
      } else {
        document.querySelector(".status").innerHTML = "Bir hata oluştu."
      }
    })
  }
  render() {
    return (
      <div className="checkup checkup-index py60 container">
        <div className="col-md-6">
          <h2 className="check-head">
            Get a free report <br /> about your website.
          </h2>
          <p>
            Your website is the place where you meet your customers and where
            you make a first impression.
            We can analyse the performance of your website and share a report
            with you - for free!
          </p>
          <input
            type="text"
            className="pink-input"
            placeholder="Enter your website."
            value={this.state.value}
            onChange={this.handleChange}
          />
          <button
            id="checkupIndexButton"
            className="red-button"
            onClick={e => this.showModal()}
          >
            Free Report
            <img alt="alt text" src={rightArrow} />
          </button>
        </div>
        <div className="col-md-6">
          <img alt="alt text" src={checkup2} />
        </div>
        <div className="check-modal">
          <span onClick={e => this.closeModal()}>X</span>
          <h2 className="form-head">Fill the form</h2>
          <p className="form-desc">
            Enter your information in the fields to get information, and we will get back to you as soon as possible.
          </p>
          <div className="check-modal_container">
            <form onSubmit={this.handleSubmit}>
              <input
                type="text"
                value={this.state.value}
                onChange={this.handleChange}
                autoFocus={true}
                name="text_204202"
                id="site"
                required
                placeholder="Enter your website."
              />
              <input
                type="text"
                id="name"
                required
                name="text_775908"
                placeholder="Enter your name"
              />
              <input
                type="email"
                id="email"
                required
                name="text_532192"
                placeholder="Enter yor email"
              />
              <button>SEND</button>
              <div className="status"></div>
            </form>
            <img alt="alt text" src={checkModal} />
          </div>
        </div>
      </div>
    )
  }
}

export default Checkup
